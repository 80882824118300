/* eslint-disable max-lines */
import { EnvZonesStores } from '@Contexts/ZonesContext/ZonesContext.types'

const b2bConfig: EnvZonesStores = {
  sit: {
    aq: {
      stores: [
        {
          name: 'Alpha I',
          isAbiStore: true,
          id: '600ad862-219a-4088-b8bd-e3d51d0ff20b',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Beta II',
          id: 'b8dd663e-3c27-44a9-b053-7bf720748767',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Gamma III',
          id: '9f7139b6-1e6c-479d-b35c-16c49b3ce787',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Delta IV',
          id: '08f95f2a-782b-40a3-9285-f8925ca6a277',
          features: { hasVideoBanners: true }
        }
      ]
    },
    ar: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'ff79a8f1-80ac-4475-90e5-a76f0a0eeee7',
          features: { hasVideoBanners: true, hasAudienceSegmentationWithVendorsList: true }
        },
        {
          name: 'Nestle',
          id: '0517d33a-eb75-41e1-8b9f-9e164d798000',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Mondelez',
          id: 'b6288cc9-a869-4922-bd58-d98bf927d183',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        }
      ]
    },
    be: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'f7f20587-3d84-4ead-a093-b2cd7e78cdfe',
          features: { hasVideoBanners: true }
        }
      ]
    },
    bo: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '4637c2ed-2e8f-45c5-b938-60b480ce91c5',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: '3fde59d3-5b46-4514-bbec-843680268da4'
        },
        {
          name: 'Pil Andina',
          id: '7c5f1a83-62ed-4ef0-83a3-10bf32083b96',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        }
      ]
    },
    br: {
      stores: [
        {
          name: 'AMBEV',
          isAbiStore: true,
          id: 'a2a5433c-aba3-4850-932b-91bbf90924fc',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: '58870cbc-7809-4e18-ba59-986b4992c842'
        },
        {
          name: 'BRF',
          id: '07c29196-a02f-4bc1-9584-3bd0908a0560',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'PepsiCO',
          id: '07aad9ae-5dd9-4c3e-8089-fa5512c15fea',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Nestlé',
          id: '15c92719-e80d-41d6-9d58-a53616a0d060',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Panpharma',
          id: 'b8459737-85ef-4221-888f-10a6cd3480ae',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Santa Cruz',
          id: '4b6fb808-1213-475f-90c9-5d346abaec41',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Servimed',
          id: '1caf03a1-eab0-4f87-97ba-2fa4d76d4b3b',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'DPC',
          id: 'aa1f9d4d-8565-4597-b4b9-bf7ce2af5e8f',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Global',
          id: 'ca9871cb-6dff-44fc-bc6d-fc24882d6d0e',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'DEMO',
          id: '1c5575d6-471f-4260-8fec-17189e9ec10a',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Vila Nova Atacado',
          id: '8fe145ff-7e6f-4c3b-b868-9885344626da',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Solfarma',
          id: '807dfe4d-e327-4e97-a648-4cb271e1689e',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Mondelez',
          id: 'efde858a-6ff8-486c-8d14-1cd8f88faeb5',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Okajima',
          id: '483cd2bf-3ac8-462e-a5d0-a8083b2aeac2',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Bimbo',
          id: '101451c3-17ed-4802-ae2f-ed4b8ef6f7e2',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        { name: 'Engage', id: '11111' },
        { name: 'MiNegocio', id: '22222', defaultVendorId: 'a2a5433c-aba3-4850-932b-91bbf90924fc' }
      ]
    },
    ca: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'd2a4d2ed-aeb0-4ec8-b8ae-ecd817fd2588',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: '3c8023c1-8d12-4f64-b11d-5f3172abf3ab'
        },
        {
          name: 'BDL',
          id: '4653b2c5-1c81-4698-b4c8-25145e7da364',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'BDL - AB',
          id: '7abf06ad-293f-4dda-aa5d-6875e42c7393',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'BDL - BC',
          id: '0950fe1c-aab0-4758-86cd-aa601704ae11',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        }
      ]
    },
    cl: {
      stores: [
        {
          name: 'Nestle',
          id: 'f359ca57-8f5b-471e-96b1-2a6387785868',
          features: { hasVideoBanners: true }
        }
      ]
    },
    co: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '6d9ccf42-96b6-4121-a1d9-24920c656086',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: 'efcffe32-990a-49d5-b1ad-3b383ee38447'
        },
        {
          name: 'Gloria',
          id: 'a6fd4781-81fc-484a-99da-7b39ee015f98',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Nestlé',
          id: '30797d66-2238-4628-8307-3791a69b27d9',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        { name: 'Engage', id: '11111' },
        { name: 'MiNegocio', id: '22222', defaultVendorId: 'efcffe32-990a-49d5-b1ad-3b383ee38447' }
      ]
    },
    de: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '46e36b54-c29d-4024-8662-3049562db9fa',
          features: { hasVideoBanners: true }
        }
      ]
    },
    do: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '7b7efc58-6c96-43e6-95fe-0640d2aee5f9',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: '5bd59910-c032-4000-910a-f1f164d6c067'
        },
        {
          name: 'MercaSID',
          id: 'ede4cfb1-0dd2-45f9-ae79-41851484d8ff',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Nestle',
          id: 'be83aad5-995c-46bf-b448-f52dda873a87',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'MiNegocio',
          id: '22222',
          defaultVendorId: '5bd59910-c032-4000-910a-f1f164d6c067'
        },
        {
          name: 'Chavon',
          id: 'b275b853-3186-4f44-99c0-038301d70abf',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        }
      ]
    },
    ec: {
      stores: [
        {
          name: 'Cerveceria Nacional',
          isAbiStore: true,
          id: 'fa76813d-0633-4afc-8596-ed699e13df6b',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: '5636a216-df72-4994-b84d-14c2b1e42ad3'
        },
        {
          name: 'Nestlé',
          id: '07018b64-07d4-43cf-9153-ea37957a50b9',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Global',
          id: '79817bb4-0a48-450c-a90f-78b51ac63618',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Mondelez',
          id: 'ee980c53-1787-4047-95cd-c11712716dae',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'EcuaJugos',
          id: '8c2d3e23-5fd8-46cf-b338-f398e2ecd35d',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        { name: 'Engage', id: '11111' },
        { name: 'MiNegocio', id: '22222', defaultVendorId: '5636a216-df72-4994-b84d-14c2b1e42ad3' }
      ]
    },
    es: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '73c80609-e5ab-4f29-9add-87d1f749d8a3',
          features: { hasVideoBanners: true }
        }
      ]
    },
    gh: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '6565bd6b-44c9-4a46-84c0-49fab66c6a4c',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: 'cc509b0c-5d11-4998-957e-58b9b8a5de81'
        }
      ]
    },
    gb: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'e6d320a2-2bbb-48db-9203-5c0336505eff',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: '65be2938-6bac-400b-bce2-482e138a48e0'
        },
        { name: 'BBG', id: 'f91529ab-bf57-48ee-96d8-168510265ea8' },
        { name: 'CAMDEN', id: '6cab0692-f216-447f-9179-892804e42c95' }
      ]
    },
    hn: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '9c604d7f-ae87-439b-8f17-e9d8849dd4d1',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: '880b68d6-00f9-48f2-a022-0dbc1467abf5'
        },
        { name: 'MiNegocio', id: '22222', defaultVendorId: '880b68d6-00f9-48f2-a022-0dbc1467abf5' }
      ]
    },
    id: {
      stores: [
        {
          name: 'Nestle - Surya Anugerah Sentosa',
          id: '46115f7e-c9ff-4bcb-9969-ed80418a7f0b',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Nestle - Rukun Mitra Sejati',
          id: '58e0fd51-02e1-4fe9-9717-ef8a22e2be8e',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Nestle - Dinamis Artha Sukses',
          id: '0a0aee06-a798-4cc7-b8ff-bd16511c60a2',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Nestle - Cipta Mandiri Perkasa',
          id: 'd831e350-d9ea-4d7b-ae2d-2db572d530fa',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Nestle - Anugerah Sinergi Raya',
          id: '1ebbfe17-3764-4940-981e-a260881de800',
          features: { hasVideoBanners: true }
        }
      ]
    },
    kr: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'c36a9554-1a7b-4ef7-bdb5-8d31619add2a',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        }
      ]
    },
    mx: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'c5ac68ef-34e0-4649-b70b-6cd7153bfeef',
          features: { hasVideoBanners: true, hasAudienceSegmentationWithVendorsList: true }
        },
        {
          name: 'Nestle',
          id: 'b8860915-23e3-41a9-b944-714b9930cd71',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Barcel',
          id: '829cd00a-e856-4a60-8657-cc915dca1e2f',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        { name: 'Engage', id: '11111' },
        { name: 'MiNegocio', id: '22222', defaultVendorId: 'df9c1f38-a223-4d03-95ba-85f83c3daa10' }
      ]
    },
    nl: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '68dc869e-930c-4547-93a0-f0a372e8ad07',
          features: { hasVideoBanners: true }
        }
      ]
    },
    pa: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '85ac0e19-9793-4cd0-861b-44dd048efc4a',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: '2ebdd41f-77c3-418d-afd7-69deb33fe214'
        },
        { name: 'MiNegocio', id: '22222', defaultVendorId: '2ebdd41f-77c3-418d-afd7-69deb33fe214' }
      ]
    },
    pe: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '7937d20b-b838-4682-827e-0f75b9380506',
          features: { hasVideoBanners: true, hasAudienceSegmentationWithVendorsList: true }
        },
        {
          name: 'Gloria',
          id: '5080831a-3cbe-424f-a0c3-a0b3ff8f49ad',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Global',
          id: '16cc94e5-e67d-41c2-83d5-38ca6c1eaa2e',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Nestlé',
          id: 'b8433cb7-8e67-42b6-8a6f-0cb6dc1b7018',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: `D'Onofrio`,
          id: '49a6a987-4bee-4fde-9922-12eeece9dbee',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Mondelez',
          id: '934ebfcb-ac83-4608-9dcd-95fd502d860d',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        { name: 'Engage', id: '11111' },
        { name: 'MiNegocio', id: '22222', defaultVendorId: '1f94de69-49b5-47dc-9fe7-2590487a0419' }
      ]
    },
    ph: {
      stores: [
        {
          name: 'Actiserve',
          id: 'c0bbdd19-77f8-4459-baf8-f1467d70bb44',
          features: { hasVideoBanners: true }
        },
        {
          name: 'OCSI Cavite',
          id: '0517c9a4-6a2b-436c-ac6b-89dbdce33a40',
          features: { hasVideoBanners: true }
        }
      ]
    },
    py: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '9c601bf6-d238-4041-8146-5ef2f81210c9',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        }
      ]
    },
    sv: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'f0f0b8bf-233e-4420-ae37-089569f028f3',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: 'cf1f4d68-ed9a-4af0-8513-606ed630494d'
        },
        { name: 'MiNegocio', id: '22222', defaultVendorId: 'cf1f4d68-ed9a-4af0-8513-606ed630494d' }
      ]
    },
    tz: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '0183d59a-66ce-4f86-8115-564968f2f6c0',
          features: { hasVideoBanners: true }
        }
      ]
    },
    ug: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'e5b1be9c-ff85-4394-b6f3-335e036ae8c6',
          features: { hasVideoBanners: true }
        }
      ]
    },
    us: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '12345',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentation: true
          },
          defaultVendorId: null
        }
      ]
    },
    uy: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '552adebf-bec9-4cd0-ac91-02079b32077c',
          features: { hasVideoBanners: true, hasAudienceSegmentationWithVendorsList: true }
        }
      ]
    },
    za: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'dd345f1a-ce4b-4622-a2b6-ab1b89429c36',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: '2509109d-9bd4-469e-a626-d8dae92e6689'
        },
        { name: 'MiNegocio', id: '22222', defaultVendorId: '2509109d-9bd4-469e-a626-d8dae92e6689' },
        {
          name: 'Big Save Hammanskraal',
          id: '2dc4d31f-fada-421f-9702-2944a791b43c',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Big Save Mabopane',
          id: '4c8f1272-2f71-47a9-a7ef-2e3d2abf7c55',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Big Save Liquor Waltloo',
          id: '1483df3f-cf6d-4e25-82eb-36eb1cd10dfb',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Captain Liquor Distributors',
          id: '38c5fe3f-0fcc-4baa-aa9c-4904a482e5de',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        }
      ]
    }
  },
  uat: {
    aq: {
      stores: [
        {
          name: 'Alpha I',
          isAbiStore: true,
          id: '380597ee-676a-42f0-b5ad-cfea0db788db',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Beta II',
          id: 'ce5d5b4d-9406-4082-a520-803cc70d042c',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Gamma III',
          id: '06325bd3-09fa-4a6d-9f32-b848c3d2ab31',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Delta IV',
          id: '35bfca95-e5bd-4b68-9335-967d1fe8ff32',
          features: { hasVideoBanners: true }
        }
      ]
    },
    ar: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'a8fba145-455d-43d2-b6a5-ed625ba483f4',
          features: { hasVideoBanners: true, hasAudienceSegmentationWithVendorsList: true }
        },
        {
          name: 'Nestle',
          id: 'c778556c-ee5e-4315-bd6a-6dee148f2bfd',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Mondelez',
          id: 'c7428c07-9aae-4bbb-a719-b95ee22906eb',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        }
      ]
    },
    be: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'e784344a-56bb-444f-ad1e-dc1817d5dea8',
          features: { hasVideoBanners: true }
        }
      ]
    },
    bo: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '0c17b0f0-3f56-4e9a-ac1f-d6b0910ac6bf',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: 'e55b6a7d-84a6-48ea-bb1b-f3223d91a985'
        },
        {
          name: 'Pil Andina',
          id: 'd72efa0e-59d7-44f7-83b4-2b18cb40ab2e',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        }
      ]
    },
    br: {
      stores: [
        {
          name: 'AMBEV',
          isAbiStore: true,
          id: 'a01deb9a-e47a-4e18-88e4-43018c64f03e',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: '7a51f522-c3e3-421b-89d8-290d8eb2eddd'
        },
        {
          name: 'BRF',
          id: '436605f3-f36c-4fc8-9460-dd54fb39c0b1',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'PepsiCO',
          id: 'e610cc98-d779-45db-8f10-060a8035028e',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Nestlé',
          id: '604c9ad9-55b9-4ce2-9505-c0985f8ea164',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Panpharma',
          id: '55e80899-b3ed-4ef0-9a44-e2013aa07aa5',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Santa Cruz',
          id: 'f423c1ec-1f96-40af-8fd8-766d248497ca',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Servimed',
          id: '2d6e77ee-60f0-4d5c-92df-b9ca495f5d34',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'DPC',
          id: '8b4fd81c-8b74-4a1d-8d64-0b3534586439',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Global',
          id: 'd8cc4250-bf47-40d5-adbc-8850cb1ba8b9',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'DEMO',
          id: 'e67a9da1-156f-4cbd-87e8-40f7828cf0a0',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Vila Nova Atacado',
          id: 'c0bc28ba-5df8-43a8-b2d6-a4cad03354fc',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Solfarma',
          id: 'a2f67834-8efa-4eec-8d86-6cf5c2ba8a5e',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Mondelez',
          id: '859b6462-69d1-416c-a08f-ddda4f712fbc',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Okajima',
          id: '75c4f5db-0ad1-4dd0-a768-263c60395e55',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Bimbo',
          id: '044436e4-f917-4228-b119-4573dc3b050e',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        { name: 'Engage', id: '11111' },
        { name: 'MiNegocio', id: '22222', defaultVendorId: '7a51f522-c3e3-421b-89d8-290d8eb2eddd' }
      ]
    },
    ca: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'e8cf2d79-09ef-43c9-ae76-5c18bd81d3cc',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: '540146c1-64fd-4b7c-a659-cedfc7023068'
        },
        {
          name: 'BDL',
          id: 'd2b5959c-817b-4897-a825-3626e4e09b9f',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'BDL - AB',
          id: '76daa788-eab8-4e81-aa74-6383845530de',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'BDL - BC',
          id: '54d38838-d82e-4fca-b550-13c142fbfcf7',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        }
      ]
    },
    cl: {
      stores: [
        {
          name: 'Nestle',
          id: '41b73630-3e20-4e1e-858a-1ad24d046878',
          features: { hasVideoBanners: true }
        }
      ]
    },
    co: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'eb6a886d-32f1-4555-bc5d-9a2ee8992c5e',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: 'e6929cd6-5e48-47c1-822f-4f9ac749c650'
        },
        {
          name: 'Gloria',
          id: 'c1929e7b-1225-4715-8426-2a5b540318a0',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Nestlé',
          id: '040cd7a6-1b3f-412b-ae89-871beddaeadc',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        { name: 'Engage', id: '11111' },
        { name: 'MiNegocio', id: '22222', defaultVendorId: 'e6929cd6-5e48-47c1-822f-4f9ac749c650' }
      ]
    },
    de: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '07eb712c-f677-4934-86ce-9df52c5a29b0',
          features: { hasVideoBanners: true }
        }
      ]
    },
    do: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'd6ad9fa2-b3a7-4771-84d4-9f7d228d2412',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: '27f60e79-1c0e-47d6-b31c-af787d192c1c'
        },
        {
          name: 'MercaSID',
          id: 'f9e9d1ca-5bf0-4908-8289-51345bb592fb',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Nestle',
          id: 'fe9c8a71-35d3-4779-8142-118ae73a66eb',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'MiNegocio',
          id: '22222',
          defaultVendorId: '27f60e79-1c0e-47d6-b31c-af787d192c1c'
        },
        {
          name: 'Chavon',
          id: '24264ffa-9647-43d0-bf6a-62609b411b8e',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        }
      ]
    },
    ec: {
      stores: [
        {
          name: 'Cerveceria Nacional',
          id: '8e030f4b-25dc-4eec-af43-a1992097ee9f',
          isAbiStore: true,
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: 'fb81bcba-92fc-496c-a789-b4b5af4019f1'
        },
        {
          name: 'Nestlé',
          id: 'de6848e8-a830-4651-b727-7b8e6dac26e8',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Global',
          id: '2462bf30-51cb-452b-9e05-a8f3ee44b5e0',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Mondelez',
          id: 'e2851a55-489b-4ee8-8eb5-9d0de4a2d45f',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'EcuaJugos',
          id: '80630ace-1ce4-46f5-905a-b8243709513b',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        { name: 'Engage', id: '11111' },
        { name: 'MiNegocio', id: '22222', defaultVendorId: 'fb81bcba-92fc-496c-a789-b4b5af4019f1' }
      ]
    },
    es: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'c56fec6f-8453-4df8-84f6-4550fc094560',
          features: { hasVideoBanners: true }
        }
      ]
    },
    gh: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '94ac3e84-27c8-450e-ab96-778fa006e9b8',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: '26261283-b6d7-471a-bc14-c358afc7c620'
        }
      ]
    },
    gb: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '727bc8c1-955a-497e-8578-61ea3ef42044',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: 'c7a88c2d-a1c3-469b-a7db-3df0d611a29e'
        },
        { name: 'BBG', id: 'f91529ab-bf57-48ee-96d8-168510265ea8' },
        { name: 'CAMDEN', id: '6cab0692-f216-447f-9179-892804e42c95' }
      ]
    },
    hn: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '9fea9ccb-f4df-4c3f-8e39-db39f18eda35',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: '060ea43d-4a3d-4e47-b533-579e2f4e7b35'
        },
        { name: 'MiNegocio', id: '22222', defaultVendorId: '060ea43d-4a3d-4e47-b533-579e2f4e7b35' }
      ]
    },
    id: {
      stores: [
        {
          name: 'Nestle - Surya Anugerah Sentosa',
          id: '04559d24-3255-4e02-add7-c24764931bef',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Nestle - Rukun Mitra Sejati',
          id: '73fff8e1-eee9-4853-9ff7-62db9bf96129',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Nestle - Dinamis Artha Sukses',
          id: 'eb5c7eee-8619-47e3-9715-0f4e8d710b63',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Nestle - Cipta Mandiri Perkasa',
          id: '7f52f6c6-7dc9-4047-a0d5-27533c9a4a42',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Nestle - Anugerah Sinergi Raya',
          id: '84a19121-1ddd-4b9d-90a0-e6f0ab75296f',
          features: { hasVideoBanners: true }
        }
      ]
    },
    kr: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'b880c4ca-d30f-40cc-a0c4-a61d8e718016',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        }
      ]
    },
    mx: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'ce141576-4a9d-4bad-8fd8-5bf5cce1c06c',
          features: { hasVideoBanners: true, hasAudienceSegmentationWithVendorsList: true }
        },
        {
          name: 'Nestle',
          id: '4cdd7c92-02ab-4a49-8c54-3102a9ff63b0',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Barcel',
          id: '9c7b4ac0-f7c5-48d2-9332-dfb67136a538',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        { name: 'Engage', id: '11111' },
        { name: 'MiNegocio', id: '22222', defaultVendorId: '8e09bb5d-fe87-4a30-9817-50578e58c3be' }
      ]
    },
    nl: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'b6483afa-6e2e-4dcd-96b8-eeeca82995d2',
          features: { hasVideoBanners: true }
        }
      ]
    },
    pa: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '57ad8a23-8f32-46c2-81b4-dc2419afc2a7',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: 'c45d6e80-420c-4f57-ae77-2f5847b8ea29'
        },
        { name: 'MiNegocio', id: '22222', defaultVendorId: 'c45d6e80-420c-4f57-ae77-2f5847b8ea29' }
      ]
    },
    pe: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'cb8048a7-5275-4e50-bbdd-986f3b132289',
          features: { hasVideoBanners: true, hasAudienceSegmentationWithVendorsList: true }
        },
        {
          name: 'Gloria',
          id: 'ea0302dc-0b9b-49c9-8a51-f58fc789456f',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Global',
          id: '2f5d5d4a-086c-4d92-b973-1ef0cc46d54f',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Nestlé',
          id: '105a25e2-4001-4f1f-b37e-0874eb7b5bac',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: `D'Onofrio`,
          id: 'baf9b75f-f5a6-4f49-bc5c-f6e0da588038',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Mondelez',
          id: 'e9f2136f-ba7e-44dc-af1e-db8a36fbd34c',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        { name: 'Engage', id: '11111' },
        { name: 'MiNegocio', id: '22222', defaultVendorId: 'bfab19f4-3814-40ec-8b13-e6cd9d306d0b' }
      ]
    },
    ph: {
      stores: [
        {
          name: 'Actiserve',
          id: '3105edec-f042-4352-9860-36a04ce2c56e',
          features: { hasVideoBanners: true }
        },
        {
          name: 'OCSI Cavite',
          id: 'da6600c4-7f4c-45a0-81fe-e0b4723441b5',
          features: { hasVideoBanners: true }
        }
      ]
    },
    py: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '027afce6-65a6-4bc8-8f11-2f542098f123',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        }
      ]
    },
    sv: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '1d8cacb7-7762-4dfc-b64a-fc1b5dc874d0',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: 'f3c85b1d-3e89-4df6-91a3-dc490434e025'
        },
        { name: 'MiNegocio', id: '22222', defaultVendorId: 'f3c85b1d-3e89-4df6-91a3-dc490434e025' }
      ]
    },
    tz: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '6dca9aba-acec-4ce6-abdc-702b78b0c05e',
          features: { hasVideoBanners: true }
        }
      ]
    },
    ug: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'd7543fa2-8160-451e-8073-0a923a1f2354',
          features: { hasVideoBanners: true }
        }
      ]
    },
    us: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '12345',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentation: true
          },
          defaultVendorId: null
        }
      ]
    },
    uy: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'c17c734a-2bfe-485e-886c-89dc0bb49e84',
          features: { hasVideoBanners: true, hasAudienceSegmentationWithVendorsList: true }
        }
      ]
    },
    za: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '18f694bf-ec6a-4cd5-b680-24926124f07f',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: '0b9d7773-dca3-4e06-b697-70a7d70c8f5a'
        },
        { name: 'MiNegocio', id: '22222', defaultVendorId: '0b9d7773-dca3-4e06-b697-70a7d70c8f5a' },
        {
          name: 'Big Save Hammanskraal',
          id: '682d6b60-5add-47e3-a2a0-1c584e6e4cf4',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Big Save Mabopane',
          id: 'ad2ff41d-02d0-4909-8179-2161e54987fe',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Big Save Liquor Waltloo',
          id: 'fdd97260-5d9a-4bfd-b4f1-e0212854d5b2',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Captain Liquor Distributors',
          id: '58609449-c246-4a39-b4fc-47635b15a903',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        }
      ]
    }
  },
  prod: {
    aq: {
      stores: [
        {
          name: 'Alpha I',
          isAbiStore: true,
          id: '336d7783-1ea3-4d64-9d5a-d5ffeb039542',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Beta II',
          id: '8e6df41a-4c58-42d7-a674-6d0b91ce42ca',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Gamma III',
          id: '9bf28cd4-3e26-4c12-b174-0ab357aef7a6',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Delta IV',
          id: '338eec04-b3ce-450f-9ecc-24eef9911b39',
          features: { hasVideoBanners: true }
        }
      ]
    },
    ar: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '126a4950-c0ef-4ff2-a587-ddd963349ced',
          features: { hasVideoBanners: true, hasAudienceSegmentationWithVendorsList: true }
        },
        {
          name: 'Nestle',
          id: '11fccb16-53b8-47da-8e2b-39d09384608c',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Mondelez',
          id: '9f707d07-c462-47e9-853e-94c703fa2e74',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        }
      ]
    },
    be: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '03923002-ba7c-46fb-8ae0-37f613a73bf3',
          features: { hasVideoBanners: true }
        }
      ]
    },
    bo: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '11f692d5-4b43-4488-86bd-7aa3ae4deeb4',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: '3d0a5632-6fdd-4581-b3de-4119ec0cb7ba'
        },
        {
          name: 'Pil Andina',
          id: 'c8388b27-02d1-4562-95e2-6f765249a503',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        }
      ]
    },
    br: {
      stores: [
        {
          name: 'AMBEV',
          isAbiStore: true,
          id: 'de1f0b68-f635-44dd-92c5-15a04ac10a1d',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: '7312b2db-b028-4bd9-9d8a-a8cfa006029e'
        },
        {
          name: 'BRF',
          id: 'e2104e78-a2cb-4b40-a664-608834170dc1',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'PepsiCO',
          id: '2276be28-60cd-41e8-8862-5cf412390b61',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Nestlé',
          id: '9bcfced2-15f5-48f2-a43d-be9be6c02fa8',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Panpharma',
          id: '254e8714-15d3-4c3f-b357-15d8e7aa7937',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Santa Cruz',
          id: '38e154ca-a65f-46c3-a2e2-86892d39e204',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Servimed',
          id: '49fd24dc-4917-4621-8a4a-828a68813d64',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'DPC',
          id: '5161ca18-5fe0-4728-87e9-1609b9aff9b3',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Global',
          id: 'd19be7b0-39f4-4c68-9fc2-1ab79364c25f',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Vila Nova Atacado',
          id: 'f51397f7-2d40-4c67-b728-36dbcb4c3f7c',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Solfarma',
          id: 'e612fb51-5f9a-410a-989f-1fdf498ebdae',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Mondelez',
          id: '29667f12-b988-45fc-a93a-5a33b2f3a8bd',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Okajima',
          id: 'd3815bdd-7a30-4b4f-8e28-652baed49c46',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        { name: 'Engage', id: '11111' },
        {
          name: 'MiNegocio',
          id: '22222',
          defaultVendorId: '7312b2db-b028-4bd9-9d8a-a8cfa006029e'
        }
      ]
    },
    ca: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'c64f6a50-3d16-4825-9651-583a9a4e215a',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: 'ebdf24b6-1cb7-482a-aff0-1113b6b60f9f'
        },
        {
          name: 'BDL',
          id: 'dfc3f1ed-8f10-4d9a-8314-3a33110bbf19',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'BDL - AB',
          id: '79d52858-5978-489b-b937-83f7245af754',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'BDL - BC',
          id: '3d6aadac-44cd-44f2-a710-d1eb9a77968f',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        }
      ]
    },
    cl: {
      stores: [
        {
          name: 'Nestle',
          id: 'ffe22854-8e8e-49ca-8579-8c3741a43896',
          features: { hasVideoBanners: true }
        }
      ]
    },
    co: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '4a9296fb-a28c-46e1-b71f-75a9707980be',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: 'b2cbdd59-63a4-45a4-bff4-03d08a2c474a'
        },
        {
          name: 'Gloria',
          id: 'e5f45f71-ca09-43ab-ada7-96c98dfc1ac6',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Nestlé',
          id: '1e46b475-b157-4fad-83bd-e339efbcc609',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        { name: 'Engage', id: '11111' },
        { name: 'MiNegocio', id: '22222', defaultVendorId: 'b2cbdd59-63a4-45a4-bff4-03d08a2c474a' }
      ]
    },
    de: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '6c963b60-d807-48d2-92fc-e19d9fd5a653',
          features: { hasVideoBanners: true }
        }
      ]
    },
    do: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'f4330d52-be6d-4829-afcd-507c6cddff4e',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: '382ee0e5-af72-4a12-a13e-c76d0ae81f28'
        },
        {
          name: 'MercaSID',
          id: '3a1861ae-b7a8-43cf-b505-40b3c9f82739',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Nestle',
          id: '5fe6ed4a-d95f-42c3-b293-701e88e98445',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'MiNegocio',
          id: '22222',
          defaultVendorId: '382ee0e5-af72-4a12-a13e-c76d0ae81f28'
        },
        {
          name: 'Chavon',
          id: '8b012e65-8050-49d2-93bf-a1edf8f581f2',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        }
      ]
    },
    ec: {
      stores: [
        {
          name: 'Cerveceria Nacional',
          id: 'b962c222-212e-4995-a18c-f496621aa5f8',
          isAbiStore: true,
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: 'b93edda7-d69b-40e6-8803-29c42bd67b71'
        },
        {
          name: 'Nestlé',
          id: '2d620755-9d81-422a-a1b0-b28557d81621',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Global',
          id: '776f3337-a7b5-4f11-b53a-80fad29fc06f',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Mondelez',
          id: '89888298-a1cc-48b5-8559-45b946657305',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        { name: 'Engage', id: '11111' },
        { name: 'MiNegocio', id: '22222', defaultVendorId: 'b93edda7-d69b-40e6-8803-29c42bd67b71' }
      ]
    },
    es: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '4ba0e652-5f93-44d9-9ed7-6e1c4708fc50',
          features: { hasVideoBanners: true }
        }
      ]
    },
    gh: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '23526001-ca40-4586-a87b-db38afdf1148',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: 'a2607919-9b7d-4ba2-af12-9fbf05621d45'
        }
      ]
    },
    gb: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '8ebe6023-7e56-4e59-821e-71352adc570c',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: '3aff1645-c8ab-41b3-9caa-fa2b795f2a1b'
        },
        { name: 'BBG', id: 'f91529ab-bf57-48ee-96d8-168510265ea8' },
        { name: 'CAMDEN', id: '6cab0692-f216-447f-9179-892804e42c95' }
      ]
    },
    hn: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'a5bfd193-7ee7-48cb-96f7-5af632625ab2',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: '811b3d47-d003-430c-849f-d8c26223c3ff'
        },
        { name: 'MiNegocio', id: '22222', defaultVendorId: '811b3d47-d003-430c-849f-d8c26223c3ff' }
      ]
    },
    id: {
      stores: [
        {
          name: 'Nestle - Surya Anugerah Sentosa',
          id: 'a1aac2cc-8fcd-4916-b6a8-f53d0e335113',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Nestle - Rukun Mitra Sejati',
          id: '5bc0c779-9c78-4ce4-8b8d-690079ab5606',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Nestle - Dinamis Artha Sukses',
          id: '55c6cd58-6742-440e-81fe-5019d5214fe8',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Nestle - Cipta Mandiri Perkasa',
          id: '93a8f46c-3440-49da-840e-70b2460eb4c2',
          features: { hasVideoBanners: true }
        },
        {
          name: 'Nestle - Anugerah Sinergi Raya',
          id: 'd32ebfb8-51ca-4c4e-92a5-65fe08ff488d',
          features: { hasVideoBanners: true }
        }
      ]
    },
    kr: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'ae1c7c5c-0e8c-49e8-9333-dca630b49eb3',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        }
      ]
    },
    mx: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'ab4c7d46-e2fe-4787-8726-db323c1ee1f9',
          features: { hasVideoBanners: true, hasAudienceSegmentationWithVendorsList: true }
        },
        {
          name: 'Nestle',
          id: 'b8a0546a-757d-430a-9745-09a8cb840a2d',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Barcel',
          id: '850805b9-1f0d-402d-8db8-16fd480dee40',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        { name: 'Engage', id: '11111' },
        {
          name: 'MiNegocio',
          id: '22222',
          defaultVendorId: '9a8ffe9a-85b9-4bf2-9f47-1db945988928'
        }
      ]
    },
    nl: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '4557168f-7ab4-4b75-87e5-209102238fd1',
          features: { hasVideoBanners: true }
        }
      ]
    },
    pa: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '4468ffbd-6c9f-4bfe-8b62-91365bc76004',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: '7232fc14-9c72-4041-bcb3-bc455df5c5a9'
        },
        { name: 'MiNegocio', id: '22222', defaultVendorId: '7232fc14-9c72-4041-bcb3-bc455df5c5a9' }
      ]
    },
    pe: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'c22602d9-e10e-4b3b-bc62-112c35d4dbef',
          features: { hasVideoBanners: true, hasAudienceSegmentationWithVendorsList: true }
        },
        {
          name: 'Gloria',
          id: 'eaf8d85d-3f7a-4efb-8629-b1a97ea132e0',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Global',
          id: 'fc514920-53bf-47f1-91e9-a13157175224',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Nestlé',
          id: '9a673986-f127-48b7-9c51-d789b8f82d88',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: `D'Onofrio`,
          id: 'e28d5cc4-4824-45e7-9f2e-1c2af1ebedb8',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Mondelez',
          id: '22c916c7-f008-4f06-82cd-0705874b555c',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        { name: 'Engage', id: '11111' },
        { name: 'MiNegocio', id: '22222', defaultVendorId: '3cd49095-1122-4070-a2ef-cc291ec5c498' }
      ]
    },
    ph: {
      stores: [
        {
          name: 'Actiserve',
          id: 'f033bbb1-c8f2-4b91-bb34-604ffa319aff',
          features: { hasVideoBanners: true }
        },
        {
          name: 'OCSI Cavite',
          id: '982a8dfa-f6e9-4d12-bbf7-c26aae36fdbb',
          features: { hasVideoBanners: true }
        }
      ]
    },
    py: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: 'a3401b61-9b97-4936-8562-1c6f996f33f1',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        }
      ]
    },
    sv: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '7384d74a-bfec-45cd-aca1-232d309a83c8',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: '0733368a-8c77-4030-ba70-dc3683454936'
        },
        { name: 'MiNegocio', id: '22222', defaultVendorId: '0733368a-8c77-4030-ba70-dc3683454936' }
      ]
    },
    tz: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '793879e6-71b9-428f-b597-6f51e0ac170f',
          features: { hasVideoBanners: true }
        }
      ]
    },
    ug: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '372e0c38-6a45-4b96-8c5c-a063eda3c583',
          features: { hasVideoBanners: true }
        }
      ]
    },
    us: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '12345',
          features: {
            hasVideoBanners: true,
            hasAudienceSegmentation: true
          },
          defaultVendorId: null
        }
      ]
    },
    uy: {
      stores: [
        {
          name: `ABI`,
          isAbiStore: true,
          id: '44394e12-bceb-4fa1-a27e-5c56c10ac98d',
          features: { hasVideoBanners: true, hasAudienceSegmentationWithVendorsList: true }
        }
      ]
    },
    za: {
      stores: [
        {
          name: 'ABI',
          isAbiStore: true,
          id: '166e28b1-9284-484d-898c-ad48cbac37ab',
          features: { hasVideoBanners: true, hasAudienceSegmentation: true },
          defaultVendorId: 'c861214e-1dc8-4041-85b1-43607db589a5'
        },
        { name: 'MiNegocio', id: '22222', defaultVendorId: 'c861214e-1dc8-4041-85b1-43607db589a5' },
        {
          name: 'Big Save Hammanskraal',
          id: '8c71fa56-e4b8-4b29-aa37-77d81b87e284',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Big Save Mabopane',
          id: 'da515cee-d72d-4a70-be0a-595ae925ed84',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Big Save Liquor Waltloo',
          id: '01a8c880-0d73-4970-b1cf-ca52b772f716',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        },
        {
          name: 'Captain Liquor Distributors',
          id: 'f3011f76-d373-457b-8424-f68d3d99c163',
          features: {
            hasVideoBanners: true,
            hasRestrictedStore: true,
            hasAudienceSegmentationWithVendorsList: true
          }
        }
      ]
    }
  }
}

export default b2bConfig
